import React from 'react'
import { Counter } from "./components/Counter"
import { FetchData } from "./components/FetchData"
import { FormConstants, formConstants as mockConstants } from 'portal2-frontend'
import { getFrontEndRoutes, apiMock, setApi } from 'portal2-frontend'
import { Layout } from './components/Layout'

export const api = Object.assign({}, apiMock)

api.getConstantsData = async () => {
  const response = await fetch('constants')

  let constants: FormConstants

  try {
    const serverConstants = await response.json() as unknown as FormConstants
    constants = Object.assign({}, mockConstants, serverConstants)
  }
  catch {
    constants = mockConstants
    console.error("Failed to retrive constants from server")
  }

  return constants
}

setApi(api)

export const getAppRoutes = async () => {

  const formConstants = await api.getConstantsData()

  const frontEndRoutes = getFrontEndRoutes('en', Object.assign({}, formConstants))

  const AppRoutes = frontEndRoutes.concat([
    /*
    {
      index: true,
      element: <Layout><Home /></Layout>
    },
    */
    {
      path: '/counter',
      element: <Layout><Counter /></Layout>
    },
    {
      path: '/fetch-data',
      element: <Layout><FetchData /></Layout>
    }
  ])

  return AppRoutes
}

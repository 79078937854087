import React from 'react'
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { getAppRoutes } from './AppRoutes'
import { LoadingOverlay } from 'portal2-frontend'

import './custom.css'

export interface AppProps {
  basename: string
}

export const App: React.FunctionComponent<AppProps> = ({ basename }) => {
  const [appRoutes, setAppRoutes] = React.useState<Array<RouteObject>>()

  React.useEffect(() => {
    getAppRoutes().then(appRoutes => setAppRoutes(appRoutes))
  }, [])

  if (!appRoutes) return <LoadingOverlay language='en' isLoading={true} />

  const router = createBrowserRouter(appRoutes, { basename })

  return (
    <RouterProvider router={router} />
  )
}

export default App